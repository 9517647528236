<template>
  <v-card elevation="1">
    <v-toolbar height="45" flat color="accent" light>
      <v-toolbar-title class="white--text"
        ><v-icon class="white--text" left light>{{ icons.mdiCartPlus }}</v-icon>
        {{ $route.meta.title }}</v-toolbar-title
      >
    </v-toolbar>
    <v-divider class="mb-2"></v-divider>
    <v-tabs color="accent">
      <v-tab>
        <v-icon left>{{ icons.mdiPlus }}</v-icon>
        Purchase Order
      </v-tab>
      <v-tab>
        <v-icon left>{{ icons.mdiInformation }}</v-icon>
        Additional Info
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-form class="multi-col-validation">
              <v-row>
                <v-col md="12" cols="12">
                  <v-autocomplete
                    v-model="purchaseOrder.vendor_id"
                    :items="vendors"
                    item-value="vendor_id"
                    item-text="vendor_name"
                    outlined
                    color="secondary"
                    dense
                    chips
                    deletable-chips
                    small-chips
                    label="Vendor"
                    :prepend-inner-icon="icons.mdiAccountMultipleOutline"
                    single-line
                  ></v-autocomplete>
                </v-col>

                <v-col md="6" cols="12">
                  <v-text-field
                    v-model="purchaseOrder.purchase_order_no"
                    color="secondary"
                    label="Purchase Order Number"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="purchaseOrder.part_number"
                    color="secondary"
                    label="Part Number"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="purchaseOrder.invoice_number"
                    color="secondary"
                    label="Invoice Number"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select
                    v-model="purchaseOrder.status"
                    color="secondary"
                    dense
                    outlined
                    label="Status"
                    :items="item_status"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-dialog
                    ref="invoicedate_menu"
                    v-model="invoicedate_menu"
                    :return-value.sync="purchaseOrder.invoice_date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="purchaseOrder.invoice_date"
                        label="Invoice Date"
                        color="secondary"
                        :prepend-inner-icon="icons.mdiCalendarAccount"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="purchaseOrder.invoice_date" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="invoicedate_menu = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.invoicedate_menu.save(purchaseOrder.invoice_date)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col md="6" cols="12">
                  <v-autocomplete
                    v-model="purchaseOrder.user_id"
                    :items="users"
                    item-value="user_id"
                    item-text="username"
                    outlined
                    color="secondary"
                    dense
                    chips
                    deletable-chips
                    small-chips
                    label="Notify Technician"
                    :prepend-inner-icon="icons.mdiAccountCheckOutline"
                    single-line
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="12">
                  <v-textarea
                    background-color="transparent"
                    v-model="purchaseOrder.notes"
                    color="secondary"
                    dense
                    label="Notes (Optional)"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-form class="multi-col-validation">
              <v-row>
                <v-layout v-for="orderitem in purchaseOrder.purchase_order_items" :key="orderitem.purchase_item_id">
                  <v-col cols="12" md="6">
                    <v-text-field v-model="orderitem.item" color="secondary" label="Item" dense outlined></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      v-model="orderitem.quantity"
                      @change="calculatePurchaseOrder"
                      color="secondary"
                      label="Quantity"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      v-model="orderitem.retail_price"
                      @change="calculatePurchaseOrder"
                      color="secondary"
                      label="Retail Price"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      v-model="orderitem.total"
                      color="secondary"
                      label="Total"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-layout>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="accent" rounded @click.prevent="savePurchaseOrder">
              <v-icon right dark>{{ icons.mdiContentSave }}</v-icon>
              <span>Save</span>
            </v-btn>
            <v-btn color="secondary" rounded @click.prevent="$router.push({ name: 'purchaselist' })">
              <v-icon right dark>{{ icons.mdiClose }}</v-icon>
              <span>Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  mdiAccountTie,
  mdiPlus,
  mdiCalendarAccount,
  mdiCheckCircleOutline,
  mdiInformation,
  mdiContentSave,
  mdiCart,
  mdiCartCheck,
  mdiCartPlus,
  mdiCartRemove,
  mdiClose,
  mdiAccountCheckOutline,
} from '@mdi/js'
export default {
  created: function () {
    this.getPurchaseOrders()
    this.getVendors()
    this.getTechnicians()
  },
  components: {},
  data() {
    return {
      purchaseOrder: {},
      invoicedate_menu: false,
      items: ['Credit Card', 'Cash', 'Check', 'Other'],
      item_status: ['Draft', 'Ordered', 'Received', 'Cancelled'],
      vendors: [],
      users: [],
      icons: {
        mdiAccountTie,
        mdiPlus,
        mdiInformation,
        mdiContentSave,
        mdiClose,
        mdiCheckCircleOutline,
        mdiCalendarAccount,
        mdiCart,
        mdiCartCheck,
        mdiCartPlus,
        mdiCartRemove,
        mdiAccountCheckOutline,
      },
    }
  },
  methods: {
    getPurchaseOrders: function () {
      this.$store
        .dispatch('purchaseOrder/editPurchaseOrder', {
          purchase_id: this.$route.params.id,
        })
        .then(response => {
          this.purchaseOrder = this.fetchPurchaseOrders
        })
        .catch(err => {
          console.log(err)
        })
    },
    getVendors: function () {
      this.$store
        .dispatch('vendor/fetchVendors')
        .then(response => {
          this.vendors = this.fetchVendors
        })
        .catch(err => {
          console.log(err)
        })
    },

    getTechnicians: function () {
      this.$store
        .dispatch('user/fetchUsers')
        .then(response => {
          this.users = this.fetchUsers
        })
        .catch(err => {
          console.log(err)
        })
    },

    savePurchaseOrder: function () {
      this.$store
        .dispatch(`purchaseOrder/updateNewPuchaseOrder`, {
          purchase_id: this.$route.params.id,
          data: this.purchaseOrder,
        })
        .then(response => {
          const Toast = this.$swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })

          Toast.fire({
            icon: 'success',
            title: 'Record updated successfully',
          })
          this.$router.push({ name: 'purchaselist' })
        })
        .catch(error => {
          console.log(error)
        })
    },

    calculatePurchaseOrder: function () {
      let total
      total =
        this.purchaseOrder.purchase_order_items[0].quantity * this.purchaseOrder.purchase_order_items[0].retail_price
      this.purchaseOrder.purchase_order_items[0].total = total.toFixed(2)
    },
  },

  computed: {
    ...mapGetters({
      fetchPurchaseOrders: 'purchaseOrder/fetchPurchaseOrders',
      fetchVendors: 'vendor/fetchVendors',
      fetchUsers: 'user/fetchUsers',
    }),
  },
}
</script>

<style lang="scss" scoped>
</style>